import * as React from "react"
import { graphql, HeadFC } from "gatsby"
import { SEO } from "../components/seo"
import { CaseStudy } from "./casestudy"
import Portfolio from "./portfolio"

export interface QueryReturn {
  portfolio: { casestudies: CaseStudy[] }
  tags: { distinct: string[] }
}

export default Portfolio

export const Head: HeadFC<QueryReturn> = ({ data: { tags }, location }) => (
  <SEO title={"Portfolio: ".concat(decodeURIComponent(location.pathname.split('/').pop()))} description={"Marwa Khalil's Portfolio"} pathname={location.pathname} />
)

export const query = graphql`
  query ($tag: String!) {
    tags: allCaseStudy(sort: {fields: tags, order: ASC}) {
      distinct(field: tags)
    }
    portfolio: allContentfulCaseStudy(
      sort: {fields: title, order: ASC}
      filter: {tags: {eq: $tag}}
    ) {
      casestudies: nodes {
        id
        slug
        title
        description
        tags
        image {
          id
          localFile {
						childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`