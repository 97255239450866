import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Space, BlockLink, Blockhead } from "./ui"
import { CaseStudy } from "../templates/casestudy"
import * as styles from "./casestudyCard.css"


export default function CaseStudyCard(casestudy: CaseStudy) {
  return (
    <BlockLink {...casestudy} to={`/portfolio/${casestudy.slug}`}>
      {casestudy.image && (
        <>
          <GatsbyImage className={styles.casestudyThumbnail} alt={casestudy.slug} image={getImage(casestudy.image.localFile)} />
          <Space size={3} />
        </>
      )}
      <Blockhead>
        {casestudy.title}
      </Blockhead>
    </BlockLink>
  )
}

export const query = graphql`
fragment CaseStudyContent on ContentfulCaseStudy {
  id
  slug
  title
  description
  tags
  image {
    id
    localFile {
      childImageSharp {
        gatsbyImageData(
            width: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`