import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import Layout from "../components/layout"
import CaseStudyCard from "../components/casestudyCard"
import { SEO } from "../components/seo"
import {
  Container,
  FlexList,
  Box,
  Space,
  Heading,
  VisuallyHidden,
  Button
} from "../components/ui"
import { CaseStudy } from "./casestudy"
import * as styles from "./portfolio.css"

export interface QueryReturn {
  portfolio: { casestudies: CaseStudy[] }
  tags: { distinct: string[] }
}

const Portfolio: React.FC<PageProps<QueryReturn>> = ({ data: { portfolio: { casestudies }, tags: { distinct: tags } }, location }) => {
  const selectedTag = decodeURIComponent(location.pathname.split('/').pop())
  return (
    <Layout title="Portfolio">
      <Container width="narrow">
        <VisuallyHidden><Heading as="h1">Portfolio</Heading></VisuallyHidden>
        <Space size={5} />
        <Box>
          <FlexList responsive wrap gap={3} gutter={3} variant="start" className={styles.tags}>
            <Button key={"All"} variant="tag" className={(selectedTag=="portfolio" ? styles.selectedTag : styles.tag)} to={"/portfolio"}>All</Button>
            {tags.map((tag) => (
              <Button key={tag} variant="tag" className={(selectedTag==tag ? styles.selectedTag : styles.tag)} to={"/portfolio/tag/".concat(tag)}>{tag}</Button>
            ))}
          </FlexList>
        </Box>
        <Box paddingY={4}>
          <FlexList responsive wrap gap={0} gutter={3} variant="start">
            {casestudies.map((casestudy) => (
              <Box as="li" key={casestudy.id} padding={3} width="third" data-tags={casestudy.tags}>
                <CaseStudyCard {...casestudy} />
              </Box>
            ))}
          </FlexList>
        </Box>
      </Container>
    </Layout>
  )
}

export default Portfolio

export const Head: HeadFC<QueryReturn> = ({ data: { portfolio }, location }) => (
  <SEO title={"Portfolio"} description={"Marwa Khalil's Portfolio"} pathname={location.pathname} />
)

export const query = graphql`
  query {
    tags: allCaseStudy(sort: {fields: tags, order: ASC}) {
      distinct(field: tags)
    }
    portfolio: allContentfulCaseStudy(sort: {fields: title, order: ASC}) {
      casestudies: nodes {
        ...CaseStudyContent
      }
    }
  }
`